<template>
  <div>
    <HeaderNavHomepage />

    <main class="main-wrapper">
      <div class="main-card">
        <section class="hero">
          <h1>
            HelixPay Revolutionizes Online Booking for Visayas’ Largest Water
            Parks: <br />Waterworld Cebu and Waterworld Iloilo
          </h1>
        </section>

        <div class="main">
          <div class="image-container">
            <VLazyImage src="/img/articles/waterworld-headliner.jpg" />
          </div>

          <p>
            HelixPay is delighted to pour out its support to the operations of
            Waterworld Cebu and Waterworld Iloilo, two of the major water parks
            in the Philippines. Proudly presenting diverse water-based
            attractions like giant pools, man-made rivers and coasters that
            thrill families, youths and adventure seekers of all ages, the
            Waterworld brand has established itself as a leader in the Visayan
            water park industry. After recently expanding into the theme park
            and amusement scene in March, HelixPay announced a new partnership
            with Waterworld, launching their services at the Cebu branch in June
            and the Iloilo branch last month.
          </p>

          <p>
            With HelixPay’s finest e-commerce services, Waterworld is able to
            flow into a more advanced business model. As it has been previously
            dependent on social media to promote offerings, collect entrance
            fees and manually track sales, HelixPay enabled the migration of
            Waterworld’s booking system to websites. This allows Waterworld to
            have custom digital storefronts that were provided by HelixPay.
          </p>

          <p>
            A key advantage of the HelixPay-Waterworld collaboration is stronger
            customer engagement with a focus on convenience and security.
            Highlighting a well-developed online ticketing platform that can be
            accessed via mobile and desktop, the waterpark visitors can easily
            view various packages and book their preferred schedules at
            Waterworld. They can also pay for their tickets through a wide range
            of HelixPay’s partner banks, e-wallets like Gcash, and other
            authorized outlets that they can choose from.
          </p>

          <p>
            Aside from HelixPay’s powerful ticketing solutions for Waterworld,
            customers can avail its value-added services through the websites as
            well. These include locker rentals and suite accommodation with wifi
            and air-conditioning. Moreover, they can reserve website-exclusive
            amenities like karaoke, life vests, floaters and slide mats that are
            free-of-charge.
          </p>

          <div class="image-container">
            <VLazyImage src="/img/articles/waterworld-testimonial.jpg" />
          </div>
          <p>
            Roy Salvador, Operations Manager of Waterworld Cebu praised the
            smoothly coordinated services that HelixPay performed to help them
            expand and captured the online market,
            <em>
              “Thanks to HelixPay, we generated over Php1mn in revenue in just
              two months. HelixPay is a user-friendly interface that’s easy to
              navigate and simplifies payment processing. Their client support
              team is always available to address any issues or questions, with
              prompt resolutions. I also appreciate the detailed reporting,
              especially on user purchases and the waiver system.”
            </em>
          </p>

          <p>
            Waterworld is now able to smoothly handle administrative processes
            and customer-related transactions powered by HelixPay’s innovations.
            It has the capacity to facilitate digital waiver forms to help
            guests safely prepare for their visits beforehand. In order to make
            sound decisions regarding finance and marketing, it has access to
            real-time reporting of sales data (e.g. current revenue, top
            packages availed, customer demographics).
          </p>

          <p>
            Shared plans between HelixPay and Waterworld in field of recreation
            show good prospects. They envision and work together towards
            addressing visitor needs, managing online transactions and
            leveraging data for success. As partners, they believe in delivering
            fun yet secure experiences for waterpark goers.
          </p>
        </div>
      </div>
    </main>

    <MainCCFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import VLazyImage from 'v-lazy-image';

const metaImagePath = 'img/articles/waterworld-headliner.jpg';

const articlePath =
  'articles/revolutionizes-online-booking-for-visayas-largest-water-parks';

useHead(
  buildPageMeta({
    title: `HelixPay Revolutionizes Online Booking for Visayas’ Largest Water Parks: Waterworld Cebu and Waterworld Iloilo | Ecommerce Technology for Creators & Communities`,
    description:
      'HelixPay is delighted to pour out its support to the operations of Waterworld Cebu and Waterworld Iloilo, two of the major water parks in the Philippines.',
    url: `https://www.helixpay.ph/${articlePath}`,
    image: `https://www.helixpay.ph/${metaImagePath}`,
  })
);
</script>

<style scoped>
.main-wrapper {
  background-image: url('/img/articles/water-bg.png');
  padding: 2rem 0;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-color: #94c6bb;
}

.main {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.main-card {
  background: white;
  margin: 0 auto;
  max-width: 880px;
  border-radius: 1rem;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.12);
}

.hero {
  padding: 4rem 1.5rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0 auto;
  font-size: 1.15rem;
  letter-spacing: 0.05rem;
  line-height: 1.3;
  font-weight: 700;
  max-width: 1080px;
}

.image-container {
  padding-bottom: 100%;
  background: #b5c9c4;
  position: relative;
}

.image-container img {
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}

.main p {
  margin: 2rem 1.5rem;
  font-size: 0.9rem;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .main-wrapper {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .hero {
    padding: 6rem 3rem;
  }

  .hero h1 {
    font-size: 1.75rem;
  }

  .main p {
    margin: 3rem auto;
    font-size: 1rem;
    max-width: 512px;
  }
}

@media (min-width: 1024px) {
  .hero h1 {
    font-size: 2rem;
  }

  .main p {
    font-size: 1.15rem;
    max-width: 610px;
  }
}
</style>
